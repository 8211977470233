import { ACCEPTED_ARTICLE_TYPES, RouteName } from '@/constants';
import type { ITag } from '@/interfaces';
import { replacePath } from '@/utils';
import Link from 'next/link';
import { useMemo } from 'react';
import { Anchor, RoundedBubble } from '../Bubbles.styles';

const TagBubble: React.FC<ITag> = ({ title, type, slug }) => {
  const href = useMemo(() => {
    // Tag Permutation, e.g. /news
    if (!slug) {
      return replacePath(RouteName.ArticleSummary, [type]);
    }

    // Tag, e.g. /news/news-2
    return replacePath(RouteName.Article, [type && ACCEPTED_ARTICLE_TYPES[type], slug]);
  }, [type, slug]);

  return (
    <Link href={href ?? '#'} passHref legacyBehavior>
      <Anchor>
        <RoundedBubble>{title}</RoundedBubble>
      </Anchor>
    </Link>
  );
};

export default TagBubble;
