import styled from 'styled-components';
import ArrowRight from '../../../../public/images/icons/arrow_forward.svg';

export const WordBubblesWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[16]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    margin-top: ${({ theme }) => theme.spacing[24]};
  }
`;

export const MobileWordCloudContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => `-${theme.spacing[12]}`};
  margin-left: ${({ theme }) => `-${theme.spacing[8]}`};

  & > * {
    margin-top: ${({ theme }) => theme.spacing[12]};
    margin-left: ${({ theme }) => theme.spacing[8]};
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const Arrow = styled(ArrowRight)`
  transition: 0.4s;
  width: 24px;
  height: 24px;

  path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;

const ArrowContainer = styled.div<{ disabled?: boolean }>`
  background: transparent;
  display: flex !important;
  justify-content: center;
  align-items: center;
  left: unset;
  top: -2px;
  bottom: unset;
  transform: translateY(100%);
  transition: 0.4s;
  width: 24px;
  height: 24px;
  position: absolute;
  cursor: pointer;

  ${({ disabled, theme }) =>
    disabled &&
    `
    cursor: default;
    
    ${Arrow} path {
      fill: ${theme.colors.disabledBlack} !important;
    }
  `}
`;

export const NextArrowContainer = styled(ArrowContainer)`
  right: 20px;
`;

export const PrevArrowContainer = styled(ArrowContainer)`
  right: 60px;

  ${Arrow} {
    transform: scaleX(-1);
  }
`;

export const DotsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing[24]};
`;

export const Dot = styled.div<{ isActive?: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.surface04};

  & + & {
    margin-left: ${({ theme }) => theme.spacing[4]};
  }
  
  ${({ isActive, theme }) =>
    isActive &&
    `
    background: ${theme.colors.blue};
  `}
`;

export const WordRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    display: none;
  }

  & > * ~ *  {
    margin-top: ${({ theme }) => theme.spacing[8]};
  }

  & > * { // WorldBubbleWor
    white-space: nowrap;
    display: flex;
    padding: 0 ${({ theme }) => theme.spacing[20]};
  
    & > * ~ *  {
      margin-left: ${({ theme }) => theme.spacing[8]};
    }
  
    & > *:last-child {
      padding-right: ${({ theme }) => theme.spacing[20]};
    }
  }
`;
