import Image from 'next/image';
import ReactCountryFlag from 'react-country-flag';
import styled from 'styled-components';

export const CountryFlag = styled(ReactCountryFlag)(({ theme }) => ({
  fontSize: '22px !important',
  lineHeight: '15px !important',
  marginRight: theme.spacing[4],
}));

export const Flag = styled(Image).attrs({
  width: 20,
  height: 20,
})(({ theme }) => ({
  display: 'block',
  objectFit: 'contain',
  marginRight: theme.spacing[4],
}));
