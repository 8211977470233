import MultipleStop from 'public/images/icons/multiple_stop.svg';
import styled from 'styled-components';

export const MultipleStopIcon = styled(MultipleStop).attrs({
  width: 20,
  height: 20,
})`
  margin-right: ${({ theme }) => theme.spacing[4]};

  path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;
