import { RouteName } from '@/constants';
import { replacePath } from '@/utils';
import Link from 'next/link';
import { Anchor, RectBubble, RectBubbleText } from '../../Bubbles.styles';
import { MultipleStopIcon } from './TradelaneBubble.styles';

interface TradelaneBubbleProps {
  slug: string;
}

const convertSlugToBubbleTextFormat = (slug: string) => slug.split('-').join(' ');

const TradelaneBubble: React.FC<TradelaneBubbleProps> = ({ slug }) => {
  return (
    <Link href={replacePath(RouteName.Market, [slug]) ?? '#'} passHref legacyBehavior>
      <Anchor>
        <RectBubble>
          <MultipleStopIcon />
          <RectBubbleText>{convertSlugToBubbleTextFormat(slug)}</RectBubbleText>
        </RectBubble>
      </Anchor>
    </Link>
  );
};

export default TradelaneBubble;
