import type { IWordBubble } from '@/interfaces';
import { wordBubblesModified } from '@/utils';
import { BubbleComponentMapping } from './constants';

interface BubbleWrapperProps {
  wordBubbles: IWordBubble[];
  row?: number;
}

const Bubbles: React.FC<BubbleWrapperProps> = ({ wordBubbles, row = 2 }) => {
  const renderWordBubble = (bubble: IWordBubble, index: number) => {
    const BubbleComponent = BubbleComponentMapping[bubble.__typename];
    if (!BubbleComponent) return null;

    return <BubbleComponent key={index} {...bubble} />;
  };

  const renderWordBubbles = () => {
    return wordBubblesModified(wordBubbles, row)?.map((rowData: IWordBubble[], rowIndex: number) => (
      // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
      <div key={rowIndex}>{rowData?.map(renderWordBubble)}</div>
    ));
  };

  return <>{renderWordBubbles()}</>;
};

export default Bubbles;
