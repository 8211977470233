import LocalShipping from 'public/images/icons/local_shipping.svg';
import styled from 'styled-components';

export const LocalShippingIcon = styled(LocalShipping).attrs({
  width: 20,
  height: 20,
})`
  margin-right: ${({ theme }) => theme.spacing[4]};

  path {
    fill: ${({ theme }) => theme.colors.blue};
  }
`;
