import { type IWordCloud, ResponsiveCollection } from '@/interfaces';
import { useResponsive } from '@/hooks';
import { MobileWordCloud, DesktopWordCloud } from './components';

const Slider: React.FC<IWordCloud> = (props) => {
  const { isMobile } = useResponsive([ResponsiveCollection.Mobile]);

  if (isMobile) {
    return <MobileWordCloud {...props} />;
  }

  return <DesktopWordCloud {...props} />;
};

export default Slider;
