import { ContentTypeName } from '@/constants';

import CarrierBubble from './components/CarrierBubble';
import MarketBubble from './components/MarketBubble';
// Components
import TagBubble from './components/TagBubble';
import TradelaneBubble from './components/TradelaneBubble';

export const BubbleComponentMapping: {
  // biome-ignore lint/suspicious/noExplicitAny: <explanation>
  [key in ContentTypeName]?: React.ComponentType<any>;
} = {
  [ContentTypeName.Tag]: TagBubble,
  [ContentTypeName.Market]: MarketBubble,
  [ContentTypeName.Carrier]: CarrierBubble,
  [ContentTypeName.Tradelane]: TradelaneBubble,
};
