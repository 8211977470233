import { CompanyType, RouteName } from '@/constants';
import type { IIndividualCarrier } from '@/interfaces';
import { replacePath } from '@/utils';
import Link from 'next/link';
import { Anchor, RectBubble, RectBubbleText } from '../../Bubbles.styles';
import { LocalShippingIcon } from './CarrierBubble.styles';

const CarrierBubble: React.FC<IIndividualCarrier> = ({ carrierDisplayName, carrierName, slug }) => {
  return (
    <Link href={replacePath(RouteName.IndividualCarrier, [CompanyType.carriers, slug]) ?? '#'} passHref legacyBehavior>
      <Anchor>
        <RectBubble>
          <LocalShippingIcon />
          <RectBubbleText>{carrierDisplayName || carrierName}</RectBubbleText>
        </RectBubble>
      </Anchor>
    </Link>
  );
};

export default CarrierBubble;
