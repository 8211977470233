import Bubbles from '@/components/WordCloud/Bubbles';
import { useWindowSize } from '@/hooks';
import type { IWordCloud } from '@/interfaces';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Arrow, NextArrowContainer, PrevArrowContainer, WordBubblesWrapper, WordRowContainer } from '../Slider.styles';

enum SlidePosition {
  START = 'start',
  MIDDLE = 'middle',
  END = 'end',
}

const DesktopWordCloud: React.FC<IWordCloud & React.ComponentPropsWithoutRef<'div'>> = ({ wordBubbles }) => {
  const [wrapperElm, setWrapperElm] = useState<HTMLElement | null>(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [leftPosition, setLeftPosition] = useState(0);
  const [slidePos, setSlidePos] = useState<SlidePosition | null>(SlidePosition.START);
  const outerWordCloudRef = useRef<HTMLDivElement | null>(null);
  const { width } = useWindowSize();

  const stepForward = useMemo(() => leftPosition + containerWidth - 100, [leftPosition, containerWidth]);
  const stepBackward = useMemo(() => leftPosition - containerWidth, [leftPosition, containerWidth]);

  const scrollRight = () => {
    if (!slidePos || !wrapperElm || slidePos === SlidePosition.END) return;
    if (wrapperElm && leftPosition + containerWidth < wrapperElm.scrollWidth) setLeftPosition(stepForward);
    wrapperElm?.scrollTo({
      left: stepForward,
      behavior: 'smooth',
    });
  };

  const scrollLeft = () => {
    if (!slidePos || !wrapperElm || slidePos === SlidePosition.START) return;
    const _leftPos = stepBackward > 0 ? stepBackward : 0;
    if (leftPosition > 0) setLeftPosition(_leftPos);

    wrapperElm?.scrollTo({
      left: stepBackward,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (wrapperElm && wrapperElm?.scrollWidth <= containerWidth) {
      setSlidePos(null);
    } else {
      !slidePos && setSlidePos(SlidePosition.START);
    }
  }, [wrapperElm, slidePos]);

  const handleScroll = () => {
    if (!wrapperElm) return;
    if (wrapperElm?.scrollWidth <= containerWidth) {
      slidePos && setSlidePos(null);
    } else if (wrapperElm.scrollLeft <= 0) {
      slidePos !== SlidePosition.START && setSlidePos(SlidePosition.START);
    } else if (wrapperElm.scrollLeft + containerWidth >= wrapperElm.scrollWidth - 1) {
      slidePos !== SlidePosition.END && setSlidePos(SlidePosition.END);
    } else {
      slidePos !== SlidePosition.MIDDLE && setSlidePos(SlidePosition.MIDDLE);
    }
  };

  useEffect(() => {
    const outer = outerWordCloudRef?.current;
    setWrapperElm(outer);
    setContainerWidth(outer ? outer.getBoundingClientRect()?.width : 0);
  }, [width]);

  return (
    <WordBubblesWrapper>
      <PrevArrowContainer disabled={!slidePos || slidePos === SlidePosition.START} onClick={scrollLeft}>
        <Arrow />
      </PrevArrowContainer>
      <NextArrowContainer disabled={!slidePos || slidePos === SlidePosition.END} onClick={scrollRight}>
        <Arrow />
      </NextArrowContainer>
      <WordRowContainer ref={outerWordCloudRef} onScroll={handleScroll}>
        <Bubbles wordBubbles={wordBubbles} />
      </WordRowContainer>
    </WordBubblesWrapper>
  );
};

export default DesktopWordCloud;
