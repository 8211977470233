import Bubbles from '@/components/WordCloud/Bubbles';
import { useWindowSize } from '@/hooks';
import type { IWordCloud } from '@/interfaces';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Dot, DotsContainer, WordBubblesWrapper, WordRowContainer } from '../Slider.styles';

const MobileWordCloud: React.FC<IWordCloud & React.ComponentPropsWithoutRef<'div'>> = ({ wordBubbles }) => {
  const [wrapperElm, setWrapperElm] = useState<HTMLElement | null>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const outerWordCloudRef = useRef<HTMLDivElement | null>(null);
  const { width } = useWindowSize();

  const handleSlide = (index: number) => {
    if (currentIndex === index) return;
    wrapperElm?.scrollTo({
      left: containerWidth * index,
      behavior: 'smooth',
    });
  };

  const dotsNumber = useMemo(() => {
    if (!wrapperElm?.scrollWidth) return 0;
    return Math.ceil(wrapperElm.scrollWidth / containerWidth);
  }, [containerWidth, wrapperElm]);

  const handleScroll = () => {
    if (!wrapperElm) return;
    let _index = currentIndex;
    for (let i = 0; i < dotsNumber; i++) {
      if (wrapperElm?.scrollLeft + containerWidth >= wrapperElm.scrollWidth) {
        _index = dotsNumber - 1;
      } else if (wrapperElm?.scrollLeft >= containerWidth * i && wrapperElm?.scrollLeft < containerWidth * (i + 1)) {
        _index = i;
      }
    }
    if (_index !== currentIndex) {
      setCurrentIndex(_index);
    }
  };

  useEffect(() => {
    const outer = outerWordCloudRef?.current;
    setWrapperElm(outer);
    setContainerWidth(outer ? outer.getBoundingClientRect()?.width : 0);
  }, [width]);

  return (
    <WordBubblesWrapper>
      <WordRowContainer ref={outerWordCloudRef} onScroll={handleScroll}>
        <Bubbles wordBubbles={wordBubbles} row={3} />
      </WordRowContainer>
      {dotsNumber > 1 && (
        <DotsContainer>
          {Array(dotsNumber)
            .fill({})
            .map((_, index) => (
              // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
              <Dot key={index} isActive={currentIndex === index} onClick={() => handleSlide(index)} />
            ))}
        </DotsContainer>
      )}
    </WordBubblesWrapper>
  );
};

export default MobileWordCloud;
