import { RouteName } from '@/constants';
import type { IMarket } from '@/interfaces';
import { replacePath } from '@/utils';
import Link from 'next/link';
import { Anchor, RectBubble, RectBubbleText } from '../../Bubbles.styles';
import { CountryFlag, Flag } from './MarketBubble.styles';

const MarketBubble: React.FC<IMarket> = ({ marketName, countryCode, slug }) => {
  const renderFlag = () => {
    const isRegion = !countryCode;

    if (isRegion) return <Flag src='/images/icons/flag.svg' alt='globe' />;
    return <CountryFlag countryCode={countryCode} alt={marketName} />;
  };

  return (
    <Link href={replacePath(RouteName.Market, [slug]) ?? '#'} passHref legacyBehavior>
      <Anchor>
        <RectBubble>
          {renderFlag()}
          <RectBubbleText>{marketName}</RectBubbleText>
        </RectBubble>
      </Anchor>
    </Link>
  );
};

export default MarketBubble;
